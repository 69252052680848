import { useStyles } from '@wix/tpa-settings/react';
import { useTranslation } from '@wix/yoshi-flow-editor';
import React from 'react';
import { Text } from 'wix-ui-tpa/cssVars';

import { IMAGE_ROUTE } from '../../../constants/image-route';
import { ChannelType, SocialMediaSettings } from '../../../types/domain';
import { StepThumbnail } from '../stylesParams';
import { classes } from './DetailsCard.st.css';
import { SocialMediaRule } from './SocialMediaRule';

type DetailsItem = {
  title?: string;
  description: string;
  socialMedia?: SocialMediaSettings;
};

interface DetailsCardProps {
  step: number;
  title: string;
  details: DetailsItem[];
  iconPath: string;
  dataHook?: string;
  showTiers: boolean;
  description?: string;
  followedChannels: ChannelType[];
  onFollowSocialMediaChannel?(type: ChannelType): void;
}

export const DetailsCard: React.FC<DetailsCardProps> = ({
  step,
  title,
  details,
  iconPath,
  dataHook,
  showTiers,
  description,
  followedChannels,
  onFollowSocialMediaChannel,
}) => {
  const styles = useStyles();
  const { t } = useTranslation();
  const showStepIndication = styles
    .getStylesForAllBreakpoints()
    .some((breakpointStyles) => breakpointStyles.booleans.showStepIndication);
  const showStepIcon = styles
    .getStylesForAllBreakpoints()
    .some((breakpointStyles) => breakpointStyles.numbers.cardsStepThumbnail === StepThumbnail.Icon);
  const showStepNumber = styles
    .getStylesForAllBreakpoints()
    .some((breakpointStyles) => breakpointStyles.numbers.cardsStepThumbnail === StepThumbnail.Numbers);

  const renderDetails = () => {
    if (showTiers) {
      return (
        <div className={classes.details}>
          <Text tagName="p" className={classes.detailDescription}>
            {description}
          </Text>
        </div>
      );
    }

    return (
      <div className={classes.detailsContainer}>
        <ul className={classes.details}>
          {details.map((detail, index) => {
            return (
              <li key={index} aria-label={t('app.role')} className={classes.detail}>
                {detail.title ? (
                  <Text tagName="p" className={classes.detailTitle}>
                    {detail.title}
                  </Text>
                ) : null}
                <Text tagName="p" className={classes.detailDescription} data-hook={`${dataHook}-description-${index}`}>
                  {detail.description}
                </Text>
                {detail.socialMedia && onFollowSocialMediaChannel && (
                  <SocialMediaRule
                    followedChannels={followedChannels}
                    isDetailsCard
                    socialMedia={detail.socialMedia}
                    onFollowSocialMediaChannel={onFollowSocialMediaChannel}
                  />
                )}
              </li>
            );
          })}
        </ul>
      </div>
    );
  };

  return (
    <li data-hook={dataHook} className={classes.root}>
      <div className={classes.header}>
        {showStepIndication && (
          <div className={classes.stepIndication}>
            {showStepIcon && (
              <img
                className={classes.icon}
                aria-hidden
                alt={t('app.image.program-step-icon')}
                src={IMAGE_ROUTE(iconPath)}
              />
            )}
            {showStepNumber && <Text className={classes.step}>{`0${step}`}</Text>}
          </div>
        )}
        <Text tagName="h2" className={classes.headerTitle} data-hook={`${dataHook}-title`}>
          {title}
        </Text>
      </div>
      {renderDetails()}
    </li>
  );
};
