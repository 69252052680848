export enum DataHook {
  Button = 'button',
  CoverImage = 'cover-image',
  CustomizeButton = 'customize-button',
  DetailsCardEarnPoints = 'details-card-earn-points',
  DetailsCardRedeemPoints = 'details-card-redeem-points',
  DetailsCardSignUp = 'details-card-sign-up',
  DesignDetails = 'design-details',
  DesignHeader = 'design-header',
  DesignTiers = 'design-tiers',
  DesignCurrentTier = 'design-current-tier',
  DesignTab = 'design-tab',
  DesignTabButton = 'design-tab-button',
  DisplayTab = 'display-tab',
  DisplayTabButton = 'display-tab-button',
  EmptyState = 'empty-state',
  ErrorState = 'error-state',
  ErrorStateTitle = 'error-state-title',
  ErrorStateDescription = 'error-state-description',
  Header = 'header',
  LayoutOptionGrid = 'layout-option-grid',
  LayoutOptionList = 'layout-option-list',
  LayoutOptionSide = 'layout-option-side',
  LayoutTab = 'layout-tab',
  LayoutTabHeaderSection = 'layout-tab-header-section',
  LayoutTabProgramDetailsSection = 'layout-tab-program-details-section',
  LayoutTabTiersSection = 'layout-tab-tiers-section',
  LayoutTabButton = 'layout-tab-button',
  LoyaltyPageWrapper = 'loyalty-page-wrapper',
  MainTab = 'main-tab',
  MainTabButton = 'main-tab-button',
  ManageButton = 'manage-button',
  MyRewardsWrapper = 'MyRewards-wrapper',
  ProgramName = 'program-name',
  ProgramTitle = 'program-title',
  SettingsTabs = 'settings-tabs',
  TextTab = 'text-tab',
  TextTabButton = 'text-tab-button',
  TextTabHeaderSection = 'text-tab-header-section',
  TextTabProgramDetailsSection = 'text-tab-program-details-section',
  TextTabTiersSection = 'text-tab-tiers-section',
  TiersProgram = 'tiers-program',
  LoyaltyWidget = 'loyalty-widget',
}
